import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Layout from 'components/layout';
import Title from 'components/title';
import Footer from 'components/footer/Footer';
import Pricing from '../components/pricing/pricing';

const About = ({ data }) => (
  <Layout>
    <div className="layout-div">
      <Title as="h2" size="large">
        <div className="source">{data.productsJson.title}</div>
      </Title>
    </div>
    <div style={{ height: '40vh' }}>
      <Pricing />
      <Footer footers={data.homeJson.fr.footers} />
    </div>

  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query ProductsQuery {
    productsJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    homeJson {
      fr {
        footers {
          title
          description {
            label
            link
          }
        }
      }
    }
  }
`;
