import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Copy } from '../gallery/item/item.css';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const textSecondary = `Quickly build an effective pricing table for your potential customers with this layout.
          It&apos;s built with default Material-UI components with little customization.`;

const textSecondaryFR = 'Les produits & marketing sont réalisés à partir de librairies web récentes. Nous proposons des offres mettant l\'accent sur la visibilité sur différents médias et les réseaux sociaux.';

const tiers = [
  {
    title: 'Site web',
    price: '49',
    description: [
      '4 changements / an',
      'Pages Home & Contact',
      'Design associé à votre marque',
      'Forfait à 29€/mois à partir de la 6ème année',
      'Connexion à votre mail',
      'Support Email',
    ],
    buttonText: 'Contactez nous',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pack Site web + Marketing Social',
    subheader: 'Le plus populaire',
    price: '229',
    description: [
      '4 changements / an',
      '3 campagnes marketing / an',
      'Visibilité',
      'Connexion à votre site',
      'Support Email prioritaire',
    ],
    buttonText: 'Contactez nous',
    buttonVariant: 'contained',
  },
  {
    title: 'Marketing Social',
    price: '259',
    description: [
      '5 campagnes marketing / an',
      'Marketing social sur Instagram & Facebook',
      'Diffusion ciblée',
      'Community Management',
      'Support Téléphone & Email',
    ],
    buttonText: 'Contactez nous',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>

        <Typography variant="h5" align="center" color="textSecondary" component="p">
          {textSecondaryFR}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map(line => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    <a href={"/contact"} style={{"color": "black", "underline":"none"}}>{tier.buttonText}</a>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br />
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {'Site web - Caractéristiques'}
          </Typography>
          <Divider />
          <Typography variant="subtitle1" >
            {'4 changements de contenu'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Phase d\'étude'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Maquettage sur la base de 3 modèles'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Enregistrement sur Google Maps & Apple Maps'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Enregistrement de votre nom de domaine web'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Non compris gestion multilingue +10€/mois jusqu\'à la 6ème année'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Référencement du contenu'}
          </Typography>
        </Grid>
        <br />
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {'Campagne Marketing - Caractéristiques'}
          </Typography>
          <Divider />
          <Typography variant="subtitle1" >
            {'Etude community management & réseaux sociaux'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Conception de campagne pour diffusion'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Non compris campagne hors France'}
          </Typography>
          <Typography variant="subtitle1" >
            {'Référencement du contenu des campagnes avec votre site web'}
          </Typography>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
