import { createContext } from 'react';

export const lang = {
  culture: '',
  langRoute: (culture) => {
    return culture.includes('en') ? '/en' : '';
  },
  default: 'fr'
};

export const LanguageContext = createContext(lang);
