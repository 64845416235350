import React from 'react';
import './Footer.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://www.linkedin.com/in/david-freyche-b210104"
      >
        <span className={'sourceLight'}
              style={{ 'fontSize': '12px' }}>
          Creative Apps
        </span>
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Footer = data => {
  const classes = useStyles();
  const footers = data.footers;
  return (
    <div>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers &&
          footers.map(footer => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography color="textPrimary" gutterBottom>
                  <span className={'source'} style={{ 'fontSize': '16px' }}>
                    {footer.title}
                  </span>
              </Typography>
              <ul>
                {footer.description.map(item => (
                  <li key={item.label}>
                    <Link href={item.link} color="textSecondary">
                        <span
                          className={'source-light'}
                          style={{ 'fontSize': '12px' }}
                        >
                          {item.label}
                        </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright/>
        </Box>
      </Container>
      {/* End footer */}
    </div>
  );
};

export default Footer;
