import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import { lang } from '../../../store/languageContext';

const Nav = () => (
      <Container>
        <ul>
          <li className="source-light">
            <Link to={`${lang.langRoute(lang.culture)}/contact`}>Contact</Link>
          </li>
        </ul>
      </Container>
    );

export default Nav;
